import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

let state = {
	actYear: null,
	currentLang: 'hu',
	projectView: 'card',
	sidebarShow: 'responsive',
	sidebarMinimize: true,
	asideShow: false,
	darkMode: false,
	loggedInterval: null,
	isAuthenticated: false,
	unreadNotifications: [],
}

const mutations = {
	toggleSidebarDesktop( status ) {
		const sidebarOpened = [true, 'responsive'].includes(status.sidebarShow)
		status.sidebarShow = sidebarOpened ? false : 'responsive'
	},
	toggleSidebarMobile( status ) {
		const sidebarClosed = [false, 'responsive'].includes(status.sidebarShow)
		status.sidebarShow = sidebarClosed ? true : 'responsive'
	},
	set( status, [variable, value] ) {
		status[variable] = value
	},
	toggle( status, variable ) {
		status[variable] = !status[variable]
	},
	updateLocale( status, newLocale ) {
		Vue.set(status, 'currentLang', newLocale);
	},
    setUnreadNotifications(state, notifications) {
		state.unreadNotifications = notifications;
	},
	addUnreadNotification(state, notification) {
		state.unreadNotifications.push(notification);
	},
	removeUnreadNotification(state, notificationId) {
		const index = state.unreadNotifications.findIndex(
			notification => notification.id === notificationId
		);
		if (index !== -1) {
			state.unreadNotifications.splice(index, 1);
		}
	},
	removeAllUnreadNotification(state) {
		state.unreadNotifications = [];
	},
}

const actions = {
	changeLocale({ commit }, newLocale) {
		commit('updateLocale', newLocale)
	}
}

const getters = {
	getLocale: status => status.my_state
}

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
	plugins: [createPersistedState()]
});
