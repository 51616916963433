// Websocket Csatornák figyelése
export const initChannelListener = (EchoInstance, auth) => {
	const userId = auth.uid;
	// A publikus csatorna
	EchoInstance.channel('public').listen('SendMessageToClientEvent', (e) => {
		console.log(`publikus csatorna a ${userId} ID-jű user számára`);
		console.log(e); // the data from the server
	});
	/**
	// A privát kilens csatorna - teszt!
	// Csak a userid = 1-es fogja megkapni az üzeneteket, mert a csatorna így van beállítva a backenden
	EchoInstance.private(`client.${userId}`).listen('.survey.updated', (e) => {
        console.log('privát csatorna: ', e);
    });
	*/
}
