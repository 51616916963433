<template>
	<div>
		<ModalWindow
			:keyboard="false"
			:closeOnBackdrop="false"
			:title="$t('login.title')"
			color="danger"
			:show.sync="loginModal">
			<h6><strong>{{ $t('login.reLoginText') }}.</strong></h6>
			<CForm @submit.prevent="login" method="POST">
				<div v-if="showMessage" class="alert alert-warning">
					<strong>{{ message }}</strong>
				</div>
				<CInput
					v-model="email"
					prependHtml="<i class='cil-user'></i>"
					:placeholder="$t('login.phEmail')"
					autocomplete="username email"
				/>
				<CInput
					v-model="password"
					prependHtml="<i class='cil-lock-locked'></i>"
					:placeholder="$t('login.phPassword')"
					type="password"
					autocomplete="curent-password"
				/>
			</CForm>
			<template #footer>
				<CButton @click="exitApp" color="danger">{{$t('btnCancel')}}</CButton>
				<CButton @click="reLogin" color="success">{{$t('btnAccept')}}</CButton>
			</template>
		</ModalWindow>
	</div>
</template>

<script>
import ModalWindow from '@/components/modalwindow/ModalWindow';
export default {
	name: 'LoginModal',
	components: {
		ModalWindow
	},
	// Az api plugin által jön
	props: ['router'],
	data() {
		return {
			email: '',
			password: '',
			message: '',
			loginModal: true,
			showMessage: false,
		}
	},
	methods: {
		/**
		 * Kilépünk az appból
		 */
		exitApp() {
			this.loginModal = false;
			this.$removeFromStorage(this.$authKey);
			this.router.replace({path: '/login'});
			// A biztonság kedvéért frissítjuk a cacheolt js/css állományt egy újratöltéssel
			location.reload();
		},
		/**
		 * Újra loginoltatás
		 */
		reLogin() {
			if ( !this.checkLoginErrorDatas() ) {
				this.$api.post('login', {
					email: this.email,
					password: this.password,
				})
				.then((response) => {
					this.loginModal = false;
					// Csak akkor dolgozzuk fel újra a tokent, hogyha sikeres a válasz
					if (response.status === 200) {
						const auth = {
							token: response.data.access_token,
							userEmail: this.email,
							sessionExpire: response.data.expire_timestamp,
							sessionTimeStamp: response.data.timestamp
						};
						this.$setStorage(this.$authKey, JSON.stringify(auth), true);
						this.email = '';
						this.password = '';
						// A biztonság kedvéért frissítjuk a cacheolt js/css állományt egy újratöltéssel
						location.reload();
					}
					else this.exitApp();
				})
				.catch(() => {
					this.exitApp();
				});
			}
		},
		/**
		 * Ellenőrizzük, hogy a megadott adatok a formai szabályoknak megfelelnek-e vagy sem
		 */
		checkLoginErrorDatas() {
			let error = false;
			if ( this.email == '' ) {
				error = true;
				this.message = this.$i18n.translate( 'validateNullEmailText' );
				this.showMessage = true;
			}
			else if ( !error && !this.$validatingEmail( this.email ) ) {
				error = true;
				this.message = this.$i18n.translate( 'validateErrorEmailText' );
				this.showMessage = true;
			}
			if ( !error && this.password == '' ) {
				error = true;
				this.message = this.$i18n.translate( 'validateNullPasswordText' );
				this.showMessage = true;
			}
			else if ( !error && !this.$validatingPassword( this.password ) ) {
				error = true;
				this.message = this.$i18n.translate( 'validateErrorPasswordText' );
				this.showMessage = true;
			}
			return error;
		}
	}
}
</script>
